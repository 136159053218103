var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{staticClass:"example-headImg",attrs:{"src":require("../assets/image/example/02-4banner.png")}}),_vm._m(0),_c('div',{staticClass:"head-list"},[_c('div',{staticClass:"head-list-item head-list-item-ischeck",staticStyle:{"margin-left":"26.3rem"},on:{"click":function($event){return _vm.goAssignBlock('block'+ 0,50)}}},[_c('span',{staticClass:"title"},[_vm._v("业务痛点")])]),_c('div',{staticClass:"head-list-item",on:{"click":function($event){return _vm.goAssignBlock('block'+ 1,50)}}},[_c('span',{staticClass:"title"},[_vm._v("解决方案")])]),_c('div',{staticClass:"head-list-item",on:{"click":function($event){return _vm.goAssignBlock('block'+ 2,50)}}},[_c('span',{staticClass:"title"},[_vm._v("方案收益")])])]),_c('div',{ref:"block0",staticClass:"container-1",staticStyle:{"display":"flex"}},[_vm._m(1),_vm._m(2)]),_c('div',{ref:"block1",staticClass:"container-2",staticStyle:{"display":"flex","height":"67rem"}},[_vm._m(3),_vm._m(4)]),_c('div',{ref:"block2",staticClass:"container-1",staticStyle:{"display":"flex","margin-bottom":"10rem"}},[_vm._m(5),_vm._m(6)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"head-explain"},[_c('div',{staticClass:"head-explain-title"},[_vm._v("APS高级排程")]),_c('div',{staticClass:"head-explain-content"},[_vm._v("运输、生产整体规划，需求、成本最优决策")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"purchase-content-left"},[_c('div',{staticClass:"purchase-title"},[_vm._v("业务痛点")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"purchase-content-right"},[_c('div',{staticClass:"purchase-title"},[_vm._v("成本控制")]),_c('div',{staticClass:"purchase-content"},[_vm._v("产品大规模的运输路线组合，运输成本存在可降空间;"),_c('br'),_vm._v(" 各工厂生产成本不同，合理规划生产计划，生产成本可压缩;")]),_c('div',{staticClass:"purchase-title"},[_vm._v("需求满足")]),_c('div',{staticClass:"purchase-content"},[_vm._v("客户DOI和工厂DOI满足程度不高，成本相同的情况下，存在可提升空间;"),_c('br'),_vm._v(" 中小客户需求或大客户小品类的需求时常无法得到满足;")]),_c('div',{staticClass:"purchase-title"},[_vm._v("排产效率")]),_c('div',{staticClass:"purchase-content"},[_vm._v("常规系统排产，运算速度较慢且无法并发执行；手工排产，仅凭经验计划准确率低;"),_c('br'),_vm._v(" 系统功能局限，缺乏对目标/参数不合理的分析预警功能;")]),_c('div',{staticClass:"purchase-title"},[_vm._v("业务运作")]),_c('div',{staticClass:"purchase-content"},[_vm._v("通过调整入参来改变计划排产结果，不可预知的因素多，排程结果差异大;"),_c('br'),_vm._v(" 主计划的频繁调整，会影响供应链后续流程，形成牛尾效应。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"purchase-content-left"},[_c('div',{staticClass:"purchase-title"},[_vm._v("解决方案")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"purchase-content-right"},[_c('img',{staticClass:"solution-img",attrs:{"src":require("../assets/image/example/02-4解决方案.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"purchase-content-left"},[_c('div',{staticClass:"purchase-title"},[_vm._v("方案收益")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"purchase-content-right"},[_c('div',{staticClass:"box2-2"},[_c('div',{staticClass:"card"},[_c('img',{attrs:{"src":require("../assets/image/example/02-4成本节约.svg")}}),_c('div',{staticClass:"title2-1"},[_vm._v("成本节约")]),_c('div',{staticClass:"title2-2"},[_vm._v("自动规划产品生产和运输线路最优组合，节省企业生产成本和运输成本。")])]),_c('div',{staticClass:"card"},[_c('img',{attrs:{"src":require("../assets/image/example/02-4需求满足.svg")}}),_c('div',{staticClass:"title2-1"},[_vm._v("需求满足比例提升")]),_c('div',{staticClass:"title2-2"},[_vm._v("合理规划产品的生产和发运量，提升客户DOI以及工厂DOI满足比例。")])]),_c('div',{staticClass:"card"},[_c('img',{attrs:{"src":require("../assets/image/example/02-4排产效率.svg")}}),_c('div',{staticClass:"title2-1"},[_vm._v("排产效率改善")]),_c('div',{staticClass:"title2-2"},[_vm._v("模型运算速度秒化级提升，同时配合入参校验预警，避免多项入参数据冲突导致排产结果异常，大幅提升排产整体效率。")])]),_c('div',{staticClass:"card"},[_c('img',{attrs:{"src":require("../assets/image/example/02-4排产效率.svg")}}),_c('div',{staticClass:"title2-1"},[_vm._v("业务运作优化")]),_c('div',{staticClass:"title2-2"},[_vm._v("通过调节需求满足和成本的权重系数，找到最适合企业的一个需求与成本的平衡点，带动整个供应链最优化。")])])]),_c('img',{staticClass:"benefits-img",attrs:{"src":require("../assets/image/example/02-4方案收益.png")}})])
}]

export { render, staticRenderFns }